import React from 'react';
import Layout from '../../components/Layout';
import Section from '../../components/Section';
import { Container, Row, Column } from '../../components/Grid';
import { Link } from 'gatsby';
import Image from '../../components/Image';
import Icon from '../../components/Icon';
import OutboundLink from '../../components/OutboundLink';

const EconomicPrinciplesPage = () => (
  <Layout title="Economic Principles">
    {/* ECONOMIC PRINCIPLES HERO */}
    <Section className="hero">
      <Container>
        <Row>
          <Column size={12}>
            <Image
              filename="img-hero-economic-principles.jpg"
              alt="Economic Principles Hero Image"
            />
          </Column>
        </Row>
      </Container>
    </Section>
    {/* INTRO SECTION */}
    <Section className="intro-section" id="intro-section">
      <Container>
        <Row>
          <Column size={10}>
            <h1 className="page-title">Economic Principles</h1>
            <h2 className="page-subhead">Investigate Real-World Challenges</h2>
            <p>
              Uncover timely and relevant connections to core economic
              principles in students’ everyday lives with a series of immersive
              instructional resources. Explore the ways that economic principles
              can be applied to every facet of life, and kickstart student
              interest in entrepreneurship, career exploration, world events and
              personal finance.
            </p>
          </Column>
        </Row>
      </Container>
    </Section>
    {/* VIDEO VIEWPOINT SERIES*/}
    <Section className="intro-videos">
      <Container>
        <Row>
          <Column size={6} className="intro-videos-left pr-0">
            <Image filename="img-EO-VVS.jpg" />
          </Column>
          <Column size={6} className="intro-videos-right box-shadow">
            <h3>Video Viewpoints Series</h3>
            <p>
              <strong>Topic:</strong> Economic Principles |{' '}
              <strong>Grade:</strong> 9–12
            </p>
            <p className="bold">
              People and places that shape the way economics impacts the world.
            </p>
            <p>
              Discover the intersection of economics and innovation with a
              three-part mini documentary series exploring the ways students’
              lives connect to the global community. Each video will showcase
              stories of people and places around the globe that are shaping how
              economics are impacting life as we know it.
            </p>
            <div className="asset__files">
              <Link
                to="videos/how-algae-could-change-fossil-fuel-industry"
                target="_blank"
                className="asset__file"
              >
                <Icon name="play" /> 3 Videos
              </Link>
              <OutboundLink
                to="/pdfs/2_economic-principles/CME-EconEssentials-VideoTopicSeries-EdGuide.pdf"
                target="_blank"
                className="asset__file"
              >
                <Icon name="download" /> Educator Guide (.pdf)
              </OutboundLink>
            </div>
          </Column>
        </Row>
      </Container>
    </Section>
    {/* FOUR-COLUMN ASSETS*/}
    <Section className="four-column-assets">
      <Container>
        <Row className="assets">
          <Column size={3} className="asset box-shadow br-all">
            <Image
              className="asset__image br-tl br-tr"
              filename="img-asset-supply-demand.jpg"
            />
            <div className="asset__content">
              <h4 className="asset__type">Classroom Lesson</h4>
              <h3 className="asset__title">
                Supply and Demand: What Happens When Things Change Rapidly?
              </h3>
              <p className="asset__description">
                Connect key economics concepts to current events with a timely
                activity in which students investigate real-world applications
                of extreme changes in supply or demand.
              </p>
            </div>
            <div className="asset__files card">
              <OutboundLink
                to="/pdfs/2_economic-principles/CME-Econ-Essentials-ClassroomActivity-SupplyandDemand.pdf"
                target="_blank"
                className="asset__file"
              >
                <Icon name="download" />
                Lesson (.pdf)
              </OutboundLink>
            </div>
          </Column>
          <Column size={3} className="asset box-shadow br-all">
            <Image
              className="asset__image br-tl br-tr"
              filename="img-asset-supply-chain.jpg"
            />
            <div className="asset__content">
              <h4 className="asset__type">Classroom Lesson</h4>
              <h3 className="asset__title">
                Supply Chain Disruptions: What They Are and Why They Matter?
              </h3>
              <p className="asset__description">
                Investigate the dynamic factors that contribute to supply chain
                disruptions, and the very real consequences these interruptions
                can have on our lives.
              </p>
            </div>
            <div className="asset__files card">
              <OutboundLink
                to="/pdfs/2_economic-principles/CME-HotTopicActivity-SupplyChainDisruptions-Standards.pdf"
                target="_blank"
                className="asset__file"
              >
                <Icon name="download" />
                Lesson (.pdf)
              </OutboundLink>
            </div>
          </Column>
          <Column size={3} className="asset box-shadow br-all">
            <Image
              className="asset__image br-tl br-tr"
              filename="img-asset-fueling-the-future.jpg"
            />
            <div className="asset__content">
              <h4 className="asset__type">Self-Paced Module</h4>
              <h3 className="asset__title">Fueling the Future</h3>
              <p className="asset__description">
                Explore the dynamic process that fuel companies use to set
                prices for products and services with a simulation in which
                students predict the future for an imaginary business.
              </p>
            </div>
            <div className="asset__files card">
              <OutboundLink
                to="https://live-econ-essentials-reactjs.pantheonsite.io/files/InteractiveModules/fuel/#package.json:-1"
                className="asset__file"
                data-click-type="Module Launch"
                data-click-name={'Fueling the Future'}
              >
                <Icon name="module" />
                Self-Paced Module
              </OutboundLink>
              <OutboundLink
                to="/pdfs/2_economic-principles/CME-EconEssentials-SPMEdGuide-FuelingtheFuture.pdf"
                target="_blank"
                className="asset__file"
              >
                <Icon name="download" />
                Educator Guide (.pdf)
              </OutboundLink>
            </div>
          </Column>
          <Column size={3} className="asset box-shadow br-all">
            <Image
              className="asset__image br-tl br-tr"
              filename="img-asset-facts-about-food.jpg"
            />
            <div className="asset__content">
              <h4 className="asset__type">Self-Paced Module</h4>
              <h3 className="asset__title">The Facts About Food</h3>
              <p className="asset__description">
                Ever wonder why foods cost what they do? Take students on a
                journey from farm to wallet to investigate what causes the
                prices of certain foods to increase and decrease.
              </p>
            </div>
            <div className="asset__files card">
              <OutboundLink
                to="/files/InteractiveModules/food/index.html"
                className="asset__file"
                data-click-type="Module Launch"
                data-click-name={'The Facts About Food'}
              >
                <Icon name="module" />
                Self-Paced Module
              </OutboundLink>
              <OutboundLink
                to="/pdfs/2_economic-principles/CME-EconEssentials-SPMEdGuide-FactsAboutFood.pdf"
                target="_blank"
                className="asset__file"
              >
                <Icon name="download" />
                Educator Guide (.pdf)
              </OutboundLink>
            </div>
          </Column>
        </Row>
      </Container>
    </Section>
    {/* VIDEO VIEWPOINT SERIES */}
    <Section className="video-series">
      <Container>
        <Row>
          <Column size={6} className="left">
            {/* <div className="label">Featured Content</div> */}
            <h2>Student Spotlight Series</h2>
            <h3>
              An Economist Answers Questions from Students Just Like You!{' '}
            </h3>
            <p>Topic: Global Economics | Grades: 9–12</p>
            <p>
              Global economics affects everything from the food we buy to the
              price we pay when filling up a tank of gas. Learn how economics
              plays a role in your daily life in this student-led Q&A.
            </p>
            <OutboundLink
              to="https://app.discoveryeducation.com/learn/videos/4d687327-c5fa-4d6b-992d-aa81af891c61/?embed=false&embed_origin=false"
              className="button mt-1 mr-1"
            >
              Watch Now <Icon name="caratright" />
            </OutboundLink>
            <OutboundLink
              to="/pdfs/1_intro/CME-EconEssentials-FinLitDay-Q&A.pdf"
              className="button mt-1"
            >
              Download Transcript <Icon name="download" className="ml-0.5" />
            </OutboundLink>
          </Column>
          <Column size={6} className="right">
            <Image
              filename="img-student-spotlight-series-FinLitDay.jpg"
              alt="Student Spotlight Series"
            />
          </Column>
        </Row>
      </Container>
    </Section>
    {/* STANDARDS-ALIGNED RESOURCES  */}
    <Section className="standards-aligned-resourses">
      <Container>
        <Row>
          <Column size={9}>
            <h2>Standards-Aligned Resources</h2>
            <h3>Build your lessons with handy standards guide</h3>
            <p>
              Econ Essentials resources are created in accordance with national
              education standards. Check out our standards-aligned tools to help
              build your lessons.
            </p>
            <a href="#sar-modal" className="button mt-1">
              Learn More <Icon name="caratright" />
            </a>
          </Column>
          <Column size={3}>
            <Image filename="img-standards-aligned-resources.jpg" />
          </Column>
        </Row>
      </Container>
    </Section>
    {/* SUPPORTING RESOURCES */}
    <Section className="four-column-assets">
      <Container>
        <Row>
          <h2>Supporting Resources</h2>
        </Row>
        <Row className="assets horizontal">
          <Column className="asset br-tl bb-blue">
            <Image
              className="asset__image br-tl br-tr"
              filename="img-asset-educator-roadmap.jpg"
            />
            <div className="asset__content">
              <h3 className="asset__title">
                Career & Technical Education Educator Roadmap
              </h3>
              <p className="asset__description">
                Econ Essentials resources can be used in a variety of subject
                areas—everything from economics and social studies to personal
                finance and business to career and technical education (CTE) and
                family and consumer sciences (FCS).
              </p>
              <div className="asset__files">
                <OutboundLink
                  to="/pdfs/2_economic-principles/CME-EconEssentials-CTERoadMap.pdf"
                  target="_blank"
                  className="asset__file"
                >
                  <Icon name="download" />
                  Educator Guide (.pdf)
                </OutboundLink>
              </div>
            </div>
          </Column>
          <Column className="asset br-tl bb-blue">
            <Image
              className="asset__image br-tl br-tr"
              filename="img-asset-investment-clubs.jpg"
            />
            <div className="asset__content">
              <h3 className="asset__title">
                Econ Essentials and Other Resources for Student Investment Clubs
              </h3>
              <p className="asset__description">
                Explore core economics concepts outside the classroom with Econ
                Essentials resources that are designed to work in afterschool
                clubs.
              </p>
              <div className="asset__files">
                <OutboundLink
                  to="/pdfs/2_economic-principles/CME-EconEssentials-IvestmentClubRoadMap.pdf"
                  target="_blank"
                  className="asset__file"
                >
                  <Icon name="download" />
                  Educator Guide (.pdf)
                </OutboundLink>
              </div>
            </div>
          </Column>
        </Row>
      </Container>
    </Section>
    {/* QUOTE CALLOUT */}
    <Section className="quote-callout">
      <Container>
        <Row>
          <Column size={7} className="quote-callout__text">
            <h3 className="quote-callout__quote">
              “The real-life examples within these programs help our students to
              really get that context to be successful outside the classroom.”
            </h3>
            <p className="quote-callout__source">
              <span className="quote-callout__source-name">
                Jacqueline Collins
              </span>
              <br />
              Business Teacher, Mansfield High School
              <br />
              Mansfield, Massachusetts
            </p>
          </Column>
          <Column size={5} className="quote-callout__image pt-2">
            <Image filename="img-ep-quote-banner.svg" />
          </Column>
        </Row>
      </Container>
    </Section>
    {/* ABOVE SIGNUP FOR UPDATES BANNER */}
    <Section className="above-footer-banner">
      <Container>
        <Row>
          <Column>
            <Image
              filename="economic-principles-above-footer-banner.jpg"
              className="bb-orange"
            />
          </Column>
        </Row>
      </Container>
    </Section>
    {/* SIGNUP FOR UPDATES */}
    <Section className="signup-updates">
      <Container>
        <Row>
          <Column size={7}>
            <h2>Sign Up for Updates</h2>
            <p>
              Want to be among the first to learn about exciting new resources
              and upcoming learning opportunities? Stay connected with Econ
              Essentials and sign up to receive updates today!
            </p>
            <OutboundLink
              to="https://info.discoveryeducation.com/Econ-Essentials-Powered-by-Futures-Fundamentals_Opt-Ins.html"
              className="button mt-1"
            >
              Learn More <Icon name="caratright" />
            </OutboundLink>
          </Column>
          <Column size={4} offset={1}>
            <Image filename="hp-signip-map.svg" />
          </Column>
        </Row>
      </Container>
    </Section>
    {/* PURE CSS STANDARDS-ALIGNED RESOURCES POPUP |
        THIS SHOULD USE THE MODAL COMPONENT */}
    <div id="sar-modal" className="overlay">
      <div className="sar">
        <a className="close" href="#">
          &times;
        </a>
        <h2 className="sar__heading">Standards-Aligned Resources</h2>
        <p>
          Download the standards below to reference the corresponding resource
          and standards connection.
        </p>
        <ul className="sar__resources">
          <li>
            <OutboundLink
              to="/pdfs/standards-aligned-resources/CME-EconEssentials-Standards-Correlation-OverviewChart.pdf"
              target="_blank"
              className="sar__resource"
            >
              <Icon name="download" /> Overview of National Standards
              Correlations
            </OutboundLink>
          </li>
          <li>
            <OutboundLink
              to="/pdfs/standards-aligned-resources/CME-EconEssentials-Standards-Voluntary-Correlation.pdf"
              target="_blank"
              className="sar__resource"
            >
              <Icon name="download" /> Voluntary National Content Standards in
              Economics
            </OutboundLink>
          </li>
          <li>
            <OutboundLink
              to="/pdfs/standards-aligned-resources/CME-EconEssentials-Standards-PersFin-Correlation.pdf"
              target="_blank"
              className="sar__resource"
            >
              <Icon name="download" /> National Standards for Personal Financial
              Education
            </OutboundLink>
          </li>
          <li>
            <OutboundLink
              to="/pdfs/standards-aligned-resources/CME-EconEssentials-Standards-SS-Correlation.pdf"
              target="_blank"
              className="sar__resource"
            >
              <Icon name="download" /> C3 Framework for Social Studies State
              Standards
            </OutboundLink>
          </li>
          <li>
            <OutboundLink
              to="/pdfs/standards-aligned-resources/CME-EconEssentials-Standards-FCS-Correlation.pdf"
              target="_blank"
              className="sar__resource"
            >
              <Icon name="download" /> National Standards for Family and
              Consumer Sciences Education
            </OutboundLink>
          </li>
          <li>
            <OutboundLink
              to="/pdfs/standards-aligned-resources/CME-EconEssentials-Standards-Business-Correlation.pdf"
              target="_blank"
              className="sar__resource"
            >
              <Icon name="download" /> National Standards for Business Education
            </OutboundLink>
          </li>
          <li>
            <OutboundLink
              to="/pdfs/standards-aligned-resources/CME-EconEssentials-Standards-CT-Correlation.pdf"
              target="_blank"
              className="sar__resource"
            >
              <Icon name="download" /> Common Career Technical Core
            </OutboundLink>
          </li>
        </ul>
      </div>
    </div>
  </Layout>
);

export default EconomicPrinciplesPage;
